import React from 'react';
import SectionTitle from '../../SectionTitle/SectionTitle';
import IndividualAssessments from './IndividualAssessments';
import CareerAssessment from './CareerAssessment';
import Workshop from './Workshop';

const PersonalTransformation = () => {
    return (
        <div id="personalTransformation" className="section">
            <SectionTitle title="TRANSFORMACIÓN PERSONAL" />
            <IndividualAssessments />
            <CareerAssessment />
            <Workshop />
        </div>
    )
}

export default PersonalTransformation;