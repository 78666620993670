import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import workshopBubble from '../../../images/workshop-bubble.png'
import { generateWhatsAppLink } from '../../../util/whatsapp';

const Workshop = () => {
    return (
        <Container style={{ marginBottom: 0 }}>
            <Row noGutters>
                <Col xs={12} md={6}>
                    <div className="image-container workshop">
                        <span className="pin workshop">
                            <img src={workshopBubble} alt="workshop-bubble" />
                        </span>
                        <div className="sub-title">
                            TALLERES DE TRANSFORMACIÓN PERSONAL Y PROFESIONAL
                        </div>
                        <p>
                            Nuestros talleres están diseñados para ofrecer oportunidades
                            de desarrollo complementario: personal y profesional para potenciar el talento.
                        </p>
                        <p>
                            Cada taller está diseñado en función de las necesidades de nuestros segmentos.
                        </p>
                        <a href="https://docs.google.com/forms/d/1ldu0RMnvLMfl2FO64a0vBGXL5Q7CDjiDwUG6n9hhq_Y/edit" target="_blank" className="workshop-poll" rel="noreferrer">CUESTIONARIO DE NECESIDADES PERSONALES</a>
                    </div>
                </Col>
                <Col className="workshop-items" xs={12} md={6}>
                    <div className="first">
                        <div className="workshop-subtitle">
                            TALLER DE DISEÑO Y ELABORACIÓN DE CURRICULUM VITAE
                        </div>
                        <div className="workshop-description">
                            Cómo crear un CV llamativo y que “venda”. 
                        </div>
                        <div className="link-container">
                            <a href={generateWhatsAppLink('Taller de diseño y elaboración de curriculum vitae')} target="_blank" className="workshop-more-info" rel="noreferrer">
                                MAS INFORMACIÓN
                            </a >
                        </div>
                    </div>
                    <div className="second">
                        <div className="workshop-subtitle">
                            TALLER DE PREPARACIÓN DE ENTREVISTAS LABORALES
                        </div>
                        <div className="workshop-description">
                            Qué elementos considerar ante una entrevista laboral.
                            Cómo manejar la entrevista. Qué información debo buscar antes de la entrevista.
                            Errores comunes de los candidatos en las entrevistas
                        </div>
                        <div className="link-container">
                            <a href={generateWhatsAppLink('Taller de preparación de entrevistas laborales')} target="_blank" className="workshop-more-info" rel="noreferrer">
                                MAS INFORMACIÓN
                            </a >
                        </div>
                    </div>
                    <div className="third">
                        <div className="workshop-subtitle">
                            TALLER DE IMAGEN PERSONAL O "BRANDING PERSONAL"
                        </div>
                        <div className="workshop-description">
                            Cómo manejar mi “marca” personal? Qué quiero comunicar a los diferentes públicos?
                        </div>
                        <div className="link-container">
                            <a href={generateWhatsAppLink('Taller de imagen personal o branding personal')} target="_blank" className="workshop-more-info" rel="noreferrer">
                                MAS INFORMACIÓN
                            </a >
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Workshop;