import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionTitle from '../../SectionTitle/SectionTitle';

import addressImage from '../../../images/address.png';
import mailImage from '../../../images/mail.png';
import phoneImage from '../../../images/phone.png';
import logo2 from '../../../images/logo2.png';
import fbImage from '../../../images/fb.png';
import whatsAppImage from '../../../images/whatsapp.png';
import ContactForm from './ContactForm';
import { generateWhatsAppLink } from '../../../util/whatsapp';

const Contact = () => {
    return (
        <div id="contact" className="section">
            <SectionTitle title="CONTACTO" />
            <Container>
                <Row>
                    <Col md={5}>
                        <img src={logo2} alt="logo2" className="contact-logo" />
                        <div className="contact-info-container">
                            <img src={addressImage} alt="house" />
                            <span>Av. La Merced # 100 Cota Cota, La Paz</span>
                        </div>
                        <div className="contact-info-container">
                            <img src={mailImage} alt="house" />
                            <span>mtedesqui@metamorphosisbolivia.com</span>
                        </div>
                        <div className="contact-info-container">
                            <img src={phoneImage} alt="house" />
                            <span>78965522</span>
                        </div>
                        <div className="social-networks">
                            <a href="https://www.facebook.com/Metamorphosis-105195988047145" target="_blank" rel="noreferrer">
                                <img src={fbImage} alt="facebook"/>
                            </a>
                            <a href={generateWhatsAppLink('todos sus servicios')} target="_blank" rel="noreferrer">
                                <img src={whatsAppImage} alt="whatsapp"/>
                            </a>
                        </div>
                    </Col>
                    <Col md={7}>
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Contact;