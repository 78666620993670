import * as React from "react"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import Company from "../components/sections/Company"
import Contact from "../components/sections/Contact/Contact"
import OrganizationalTransformation from "../components/sections/OrganizationalTransformation/OrganizationalTransformation"
import OurPurpose from "../components/sections/OurPurpose"
import PersonalTransformation from "../components/sections/PersonalTransformation/PersonalTransformation"

import '../style/index.scss';
import 'fontsource-montserrat/400.css';
import 'fontsource-montserrat/500.css';
import 'fontsource-montserrat/700.css';

const IndexPage = () => {
  return (
    <main>
      <Header />
      <Company />
      <OurPurpose />
      <PersonalTransformation />
      <OrganizationalTransformation />
      <Contact />
      <Footer />
    </main>
  )
}

export default IndexPage
