import React from 'react';
import SecondLogo from '../../images/second-logo.png';

const Company = () => {
    return (
        <div id="company">
            <div className="brand-name">METAMORPHOSIS</div>
            <div className="company-name">Iturri &amp; Tedesqui Consultores</div>
            <div className="separator">
                <img src={SecondLogo} className="second-logo" alt="logo" />
            </div>
            <div className="slogan-name">LA TRANSFORMACIÓN</div>
            <div className="slogan-name">EMPIEZA AQUI</div>
        </div>
    )
}

export default Company;