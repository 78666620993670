import React, { useState } from 'react'

const ViewMore = ({ children }) => {
    const [ isExpanded, setExpanded] = useState(false);

    const onButtonClick = () => {
        setExpanded(prevVal => !prevVal);
    }

    return (
        <div className="view-more">
            {isExpanded && children}
            <div>
                <button onClick={onButtonClick}>
                    {isExpanded? 'VER MENOS' : 'VER MÁS'}
                    <span>{isExpanded? '^' : <>&gt;</>}</span>
                </button>
            </div>
        </div>
    )
}

export default ViewMore;