import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import assessmentsIcon from '../../../images/assessments.png'
import ViewMore from '../../ViewMore/ViewMore';
import { generateWhatsAppLink } from '../../../util/whatsapp';

const IndividualAssessments = () => {
    return (
        <Container>
            <Row noGutters>
                <Col xs={12} md={6}>
                    <div className="image-container individual">
                        <span className="pin assessment">
                            <img src={assessmentsIcon} alt="assessments" />
                        </span>
                        <div className="sub-title">
                            ASSESSMENTS INDIVIDUALES
                        </div>
                        <p>
                            Te brindamos asesoramiento para cambiar de ACTITUD
                            y poder vender tu APTITUD.  
                        </p>
                        <p>
                            Nuestras evaluaciones permiten identificar tus fortalezas,
                            áreas de mejora, formas de comunicación, inteligencia emocional y motivadores entre otras.
                        </p>
                        <ViewMore>
                            <p>
                                Los resultados obtenidos en las pruebas te ayudarán a “venderte” mejor en el mercado laboral
                                en base a tus habilidades y talento. Las pruebas online se complementan con sesiones explicativas
                                y de retroalimentación. Te ayudaremos a identificar tus Factores Clave de Éxito en tu
                                desarrollo personal y profesional.
                            </p>
                        </ViewMore>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <Accordion defaultActiveKey="7">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7">
                                <div>EVALUACIÓN DISC</div>
                                <div>Dominio, Influencia, Estabilidad y Cumplimiento</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    <div className="description">
                                        Cómo respondes a retos y problemas, cómo tomas decisiones, cómo interactúas con personas,
                                        y cuál es tu ritmo y constancia de trabajo son algunos de los elementos que evaluaremos con esta prueba.
                                        También será la base para identificar tus factores clave de éxito y tu ambiente ideal de trabajo.
                                    </div>
                                    <a href={generateWhatsAppLink('Evaluación DISC')} target="_blank" className="personal-more-info" rel="noreferrer">MAS INFORMACIÓN</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                <div>EVALUACIÓN DE INTELIGENCIA EMOCIONAL</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="description">
                                        Cómo manejas tus relaciones interpersonales, eres empático, cómo controlas tus emociones, cuáles son tus fortalezas y debilidades en este campo. Esta prueba nos servirá de base para identificar con qué tipo de trabajos, puestos y culturas te identificas de mejor manera.
                                    </div>
                                    <a href={generateWhatsAppLink('Evaluación de Inteligencia Emocional')} target="_blank" className="personal-more-info" rel="noreferrer">MAS INFORMACIÓN</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <div>EVALUACIÓN DE MOTIVADORES</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <div className="description">
                                        Qué factores te “impulsan” “impulsan” a hacer lo que haces, y por qué hay actividades o temas que no te interesan. Los motivadores nos mostrarán con qué tipo de trabajos, empresas u organizaciones puedes generar un mejor “alineamiento” o “fit”.
                                    </div>
                                    <a href={generateWhatsAppLink('Evaluación de Inteligencia Emocional')} target="_blank" className="personal-more-info" rel="noreferrer">MAS INFORMACIÓN</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                <div>EVALUACIÓN INTEGRADA DE COMPORTAMIENTO E INTELIGENCIA EMOCIONAL</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <div className="description">
                                        Cómo se combinan los comportamientos y la inteligencia emocional? Qué elementos podemos encontrar como potenciadores en nuestro perfil en base a estos dos factores?
                                    </div>
                                    <a href={generateWhatsAppLink('Evaluación Ingegrada de Comportamiento e Ingeligencia Emocional')} target="_blank" className="personal-more-info" rel="noreferrer">MAS INFORMACIÓN</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                <div>EVALUACIÓN INTEGRADA DE COMPORTAMIENTO Y MOTIVADORES</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <div className="description">
                                        Cómo se combinan los comportamientos y los motivadores? En esta evaluación combinada podemos descubrir el porqué de nuestras acciones y los comportamientos resultantes.
                                    </div>
                                    <a href={generateWhatsAppLink('Evaluación Integrada de Comportamiento y Motivadores')} target="_blank" className="personal-more-info" rel="noreferrer">MAS INFORMACIÓN</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                <div>EVALUACIÓN TRIDIMENSIONAL: Comportamientos, inteligencia emocional y motivadores</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <div className="description">
                                        Una evaluación completa e integrada que permite el análisis de comportamientos, motivadores e inteligencia emocional. Los tres elementos combinados dan lugar a un perfil detallado de la persona.
                                    </div>
                                    <a href={generateWhatsAppLink('Evaluación Tridimensional')} target="_blank" className="personal-more-info" rel="noreferrer">MAS INFORMACIÓN</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    )
}

export default IndividualAssessments;