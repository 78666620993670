import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ViewMore from '../../ViewMore/ViewMore'

import cultureImage from '../../../images/culture.png';
import { generateWhatsAppLink } from '../../../util/whatsapp';

const Culture = () => {
    return (
        <Container className="org-section">
            <Row noGutters>
                <Col>
                    <img src={cultureImage} className="organizational-image" alt="cultura" />
                    <h3 className="subtitle">CULTURA</h3>
                    <p className="description">
                        “Las organizaciones son como las huellas digitales: únicas” Cada organización
                        adquiere personalidad propia; y, al mismo tiempo, se generan subculturas en las áreas y equipos.
                        Las culturas deben ser capaces de armonizar y funcionar como los engranajes más perfectos.
                    </p>
                    <ViewMore>
                        <div className="description">
                            Te brindamos asesoramiento en la valoración de culturas presentes en la organización y su adecuado
                            funcionamiento o contraposiciones. Nuestra meta es hacer que nuestros clientes logren sus indicadores
                            de gestión y resultados a través de la armonía organizacional.
                        </div>
                    </ViewMore>
                    <a href={generateWhatsAppLink('Cultura Organizacional')} target="_blank" className="more-info" rel="noreferrer">MAS INFORMACIÓN</a>
                </Col>
            </Row>
        </Container>
    )
}

export default Culture;