import React from 'react';
import SectionTitle from '../../SectionTitle/SectionTitle';
import Culture from './Culture';
import Evaluations from './Evaluations';
import OrganizationalWorkshops from './OrganizationalWorkshops';
import Teams from './Teams';

const OrganizationalTransformation = () => {
    return (
        <div id="organizationalTransformation" className="section">
            <SectionTitle title="TRANSFORMACIÓN ORGANIZACIONAL" />
            <Teams />
            <Culture />
            <Evaluations />
            <OrganizationalWorkshops />
        </div>
    )
}

export default OrganizationalTransformation;