import React, { useState } from 'react';
import axios from 'axios';
import ContactInput from './ContactInput';

const ContactForm = () => {
    const [successMessage, setSuccessMessage] = useState(false);
    const [isFormValid, setFormValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setMail] = useState('');

    // checkboxes
    const [teamEvaluationMngmt, setTeamEvaluationMngmt] = useState(false);
    const [teamEvaluationNoMngmt, setTeamEvaluationNoMngmt] = useState(false);
    const [communication, setCommunication] = useState(false);
    const [teamEnv, setTeamEnv] = useState(false);
    const [relationshipProblems, setRelationshipProblems] = useState(false);
    const [others, setOthers] = useState(false);

    const onChangeCheckbox = (onChangeFn) => (ev) => {
        onChangeFn(ev.target.value)
    }

    const onSendContactForm = () => {
        const interests = [
            {name: 'Evaluación de equipo gerencial', value: teamEvaluationMngmt},
            {name: 'Evaluación de equipo no gerencial', value: teamEvaluationNoMngmt},
            {name: 'Comunicación', value: communication},
            {name: 'Clima dentro del equipo', value: teamEnv},
            {name: 'Problemas de relacionamiento', value: relationshipProblems},
            {name: 'Otros', value: others},
        ]
            .filter(item => item.value)
            .map(item => item.name)
            .join(', ');

        if (
            !company ||
            !name ||
            !phone ||
            !email ||
            !role
        ) {
            setFormValid(false);
            return;
        } else {
            setFormValid(true);
        }

        setLoading(true);
        axios.post('https://us-east1-rinoscript-pages.cloudfunctions.net/saveMetamorphosisClientData', {
            company,
            name,
            role,
            phone,
            email,
            interests
        })
            .then(() => {
                setSuccessMessage(true);
                setCompany('');
                setName('');
                setRole('');
                setPhone('');
                setMail('');
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 3000);
            })
            .catch(() => {
                console.log('failure')
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className="contact-form">
            <h5>FORMULARIO DE CONSULTA A EMPRESA</h5>
            {successMessage && (
                <div className="form-success">
                    Se envió correctamente
                </div>
            )}
            {!isFormValid && (
                <div className="form-error">
                    Complete todos los campos requeridos
                </div>
            )}
            <form>
                <ContactInput
                    label="EMPRESA"
                    value={company}
                    onChange={ev => setCompany(ev.target.value)} />
                <ContactInput
                    label="NOMBRE"
                    value={name}
                    onChange={ev => setName(ev.target.value)} />
                <ContactInput
                    label="CARGO QUE OCUPA"
                    value={role}
                    onChange={ev => setRole(ev.target.value)} />
                <div className="checkbox-container">
                    <span>
                        <input 
                            type="checkbox"
                            checked={teamEvaluationMngmt}
                            onChange={onChangeCheckbox(setTeamEvaluationMngmt)} />
                            Evaluación de equipo gerencial
                    </span>
                    <span>
                        <input
                            type="checkbox"
                            checked={teamEvaluationNoMngmt}
                            onChange={onChangeCheckbox(setTeamEvaluationNoMngmt)}  />
                            Evaluación de equipo no gerencial
                    </span>
                    <span>
                        <input
                            type="checkbox"
                            checked={communication}
                            onChange={onChangeCheckbox(setCommunication)}  />
                            Comunicación
                    </span>
                    <span>
                        <input
                            type="checkbox"
                            checked={teamEnv}
                            onChange={onChangeCheckbox(setTeamEnv)}  />
                            Clima dentro del equipo
                    </span>
                    <span>
                        <input
                            type="checkbox"
                            checked={relationshipProblems}
                            onChange={onChangeCheckbox(setRelationshipProblems)}  />
                            Problemas de relacionamiento
                    </span>
                    <span>
                        <input
                            type="checkbox"
                            checked={others}
                            onChange={onChangeCheckbox(setOthers)}  />
                            Otros
                    </span>
                </div>
                <ContactInput
                    label="TELÉFONO DE CONTACTO"
                    value={phone}
                    onChange={ev => setPhone(ev.target.value)} />
                <ContactInput
                    label="MAIL DE CONTACTO"
                    value={email}
                    onChange={ev => setMail(ev.target.value)} />
                {!loading && <button type="button" className="send-button" onClick={onSendContactForm}>ENVIAR</button>}
            </form>
        </div>
    )
}

export default ContactForm;