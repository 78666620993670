import React from 'react'

const ContactInput = ({
    label,
    value,
    onChange
}) => {
    return (
        <div className="contact-input">
            <label>{label}:</label>
            <input value={value} onChange={onChange} />
        </div>
    )
}

export default ContactInput;