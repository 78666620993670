import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import evaluationsImage from '../../../images/evaluations.png';
import SelectList from '../../SelectList/SelectList';
import { generateWhatsAppLink } from '../../../util/whatsapp';

const sections = [{
    name: 'Evaluaciones de comportamiento',
    text: 'Cómo responde la persona  a retos y problemas, cómo interactúa con personas, y cuál es su respuesta al ritmo de trabajo  y a las normas y procedimientos. Una evaluación integral que permite obtener una radiografía completa del candidato y/o evaluado.',
    moreInfoUrl: generateWhatsAppLink('Evaluaciones de comportamiento')
}, {
    name: 'Evaluación de inteligencia emocional',
    text: 'Cómo maneja la persona sus relaciones interpersonales, cómo controla sus emociones, fortalezas y debilidades en este campo que seguramente impactarán en sus relaciones laborales y con colaboradores.',
    moreInfoUrl: generateWhatsAppLink('Evaluación de inteligencia emocional')
}, {
    name: 'Evaluación de motivadores',
    text: 'Qué factores “impulsan” al candidato/evaluado a hacer lo que hace, y a priorizar su enfoque. Las diferencias en motivadores pueden crear insatisfacción en las personas que componen una organización u obligarlas a dejarla al descubrir que no existe un “fit” o alineamiento con la cultura imperante.',
    moreInfoUrl: generateWhatsAppLink('Evaluación de motivadores')
}, {
    name: 'Evaluación integrada de comportamiento e inteligencia emocional',
    text: 'Cómo se combinan los comportamientos y la inteligencia emocional? Qué elementos podemos encontrar como potenciadores en el perfil de los candidatos en base a estos dos factores? Para aquellas posiciones principalmente orientadas a trabajo en equipo y contacto con clientes.',
    moreInfoUrl: generateWhatsAppLink('Evaluación integrada de comportamiento e inteligencia emocional')
}, {
    name: 'Evaluación integrada de comportamiento y motivadores',
    text: 'Cómo se combinan los comportamientos y los motivadores? En esta evaluación combinada podemos descubrir el porqué de las acciones de nuestros colaboradores y candidatos en la organización. Permite identificar los elementos que potenciarán la satisfacción laboral en la empresa o institución.',
    moreInfoUrl: generateWhatsAppLink('Evaluación integrada de comportamiento y motivadores')
}, {
    name: 'Evaluación tridimensional: comportamientos, inteligencia emocional y motivadores',
    text: 'Una evaluación completa e integrada que permite el análisis de comportamientos, motivadores e inteligencia emocional. Un ADN detallado que permite identificar los comportamientos, la empatía y otros elementos sociales y finalmente los impulsores que determinarán la satisfacción de una persona en el puesto o la organización.',
    moreInfoUrl: generateWhatsAppLink('Evaluación tridimensional: comportamientos, inteligencia emocional y motivadores')
}]

const Evaluations = () => {
    return (
        <Container className="org-section">
            <Row noGutters>
                <Col md={5} className="left-section">
                    <img src={evaluationsImage} className="organizational-image" alt="Evaluations" />
                    <h3 className="subtitle">EVALUACIONES</h3>
                    <p className="description">
                        Diferentes tipos de evaluaciones nos permiten identificar talento, dentro y fuera de la empresa.
                        Te brindamos herramientas para poder hacer más efectivas tus contrataciones, ascensos o cambios dentro de
                        la organización. Una radiografía completa de los candidatos finalistas puede evitar errores y costos en las decisiones.
                    </p>
                </Col>
                <SelectList sections={sections} />
            </Row>
        </Container>
    )
}

export default Evaluations;