import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import careerBubble from '../../../images/career-bubble.png'
import { generateWhatsAppLink } from '../../../util/whatsapp';

const CareerAssessment = () => {
    return (
        <Container>
            <Row className="career-container" noGutters>
                <Col>
                    <div className="image-container">
                        <span className="pin career">
                            <img src={careerBubble} alt="career-bubble"/>
                        </span>
                        <div className="sub-title">
                            ASESORAMIENTO DE CARRERA
                        </div>
                        <p>
                            Cómo armar y “vender” tu curriculum? Buscas un cambio en tu carrera?
                            Sientes que has perdido el norte y tu actual trabajo ya no te motiva?
                            Tenemos planes de asesoramiento individual que te ayudarán en esos procesos de cambio.
                        </p>
                        <p>
                            Los planes empiezan con una evaluación inicial seguida de un
                            acompañamiento individual personalizado.
                        </p>
                        <a href={generateWhatsAppLink('Asesoramiento de Carrera')} target="_blank" className="career-more-info" rel="noreferrer">SOLICITA MAS INFORMACIÓN</a>
                    </div>
                </Col>
                <Col>
                    
                </Col>
            </Row>
        </Container>
    )
}

export default CareerAssessment; 