import React from 'react';

const SectionTitle = ({ title }) => {
    const titleElements = title.split(' ').map(str => <span key={str}>{str}&nbsp;</span>)
    return (
        <p className="section-title">
            {titleElements}
        </p>
    )
}

export default SectionTitle;