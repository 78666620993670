import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import orgsWorkshopImage from '../../../images/org-workshops.png';
import ViewMore from '../../ViewMore/ViewMore';
import SelectList from '../../SelectList/SelectList';
import { generateWhatsAppLink } from '../../../util/whatsapp';

const sections = [{
    name: 'Taller de comunicación y mindfulness (* NUEVO)',
    text: 'Apoyar a los equipos en la toma de conciencia de la realidad organizacional y brindarles herramientas y técnicas de comunicación que los ayuden a hacer sinergia y potenciar su eficiencia.',
    moreInfoUrl: generateWhatsAppLink('Taller de comunicación y mindfulness')
}, {
    name: 'Taller de cultura dual: presencial y remota (* NUEVO)',
    text: 'La realidad actual ha obligado a las empresas a organizarse y trabajar en diferentes modalidades: presencial, remota y una combinación de ambas. Hemos perdido efectividad, presencia y relacionamiento. Cómo coexistir de manera eficiente con estas nuevas culturas que se van formando? Ayudamos a las empresas a encontrar las respuestas y formas de enfrentarlo.',
    moreInfoUrl: generateWhatsAppLink('Taller de cultura dual: presencial y remota')
}]

const OrganizationalWorkshops = () => {
    return (
        <Container className="org-section">
            <Row noGutters>
                <Col md={5} className="left-section">
                    <img src={orgsWorkshopImage} className="organizational-image" alt="teams" />
                    <h3 className="subtitle">TALLERES DE TRANSFORMACIÓN ORGANIZACIONAL</h3>
                    <p className="description">
                        A través de talleres in company, dinámicas de equipo, evaluaciones y análisis de las
                        dinámicas internas, nos enfocamos en mejorar la cultura, la comunicación, la organización,
                        el clima y las relaciones en tu equipo de trabajo. Es hora de una TRANSFORMACIÓN ORGANIZACIONAL
                    </p>
                    <ViewMore>
                        <p className="description">
                            Los resultados de la organización son producto de las sinergias en los equipos que la componen.
                            Identificamos el problema y te sugerimos la mejor alternativa de encararlo.
                        </p>
                    </ViewMore>
                    <a href="https://docs.google.com/forms/d/1c94PDywL1rEkBKICwChMGD5YNwn8AOyKJFcG86HxVgA/edit" target="_blank" className="workshop-org-poll" rel="noreferrer">
                        Cuestionario de necesidades de empresa
                    </a>
                </Col>
                <SelectList sections={sections} />
            </Row>
        </Container>
    )
}

export default OrganizationalWorkshops;