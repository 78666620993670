import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from '../../images/logo.png'

const Header = () => {
    return (
        <Navbar collapseOnSelect expand="md" bg="navbar" variant="light">
            <Navbar.Brand href="#home">
                <img
                    height="25"
                    src={logo}
                    alt="metamorphosis logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="#ourPurpose">Nuestro Proposito</Nav.Link>
                    <Nav.Link href="#personalTransformation">Transformacion personal</Nav.Link>
                    <Nav.Link href="#organizationalTransformation">Transformacion organizacional</Nav.Link>
                    <Nav.Link href="#contact">Contacto</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;