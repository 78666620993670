import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import targetImg from '../../images/target.png';
import quoteIcon from '../../images/quote-icon.png';

const OurPurpose = () => {
    return (
        <div id="ourPurpose" className="section">
            <SectionTitle title="NUESTRO PROPÓSITO" />
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <img
                            className="target"
                            src={targetImg}
                            alt="target"
                            />
                    </Col>
                    <Col sm={12} md={6}>
                        <p className="purpose-description">
                            Es acompañar a las personas y las
                            organizaciones a lograr su máximo potencial
                            de desarrollo y éxito.
                        </p>
                        <div className="quote-container">
                            <div className="green-bar"></div>
                            <img src={quoteIcon} alt="quote" className="quote-icon" />
                            <p>
                                Más allá del estado actual, existe un estado deseado
                                para cada uno ... y es ahí donde encontrará
                                el éxito...
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OurPurpose;