import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import teamsImage from '../../../images/teams.png';
import ViewMore from '../../ViewMore/ViewMore';
import SelectList from '../../SelectList/SelectList';
import { generateWhatsAppLink } from '../../../util/whatsapp';

const sections = [{
    name: 'Taller de evaluación de equipo',
    text: 'A través de la aplicación de evaluaciones tanto individuales como de equipo (on line), se puede identificar la cultura del mismo. Esa “personalidad” de equipo nos ayudará a potenciarlo y volverlo un equipo de alto rendimiento.',
    moreInfoUrl: generateWhatsAppLink('Taller de evaluación de equipo')
}, {
    name: 'Taller de formación de equipos',
    text: 'A través de dinámicas y ejercicios de experiencia, los equipos pueden identificar y resolver problemas de la vida cotidiana dentro del equipo.',
    moreInfoUrl: generateWhatsAppLink('Taller de formación de equipos')
}, {
    name: 'Taller de comunicación y feedback',
    text: 'El 80% de los problemas interpersonales se producen por una mala o deficiente comunicación. El entorno laboral no es la excepción. Brindamos herramientas y técnicas que ayuden a mejorar la comunicación, y por lo tanto, las relaciones y clima dentro del equipo.',
    moreInfoUrl: generateWhatsAppLink('Taller de comunicación y feedback')
}]

const Teams = () => {
    return (
        <Container className="org-section">
            <Row noGutters>
                <Col xs={12} md={5} className="left-section">
                    <img src={teamsImage} className="organizational-image" alt="teams" />
                    <h3 className="subtitle">EQUIPOS</h3>
                    <p className="description">
                        Has pensado que las relaciones dentro de los equipos de trabajo pueden incidir
                        en los resultados que tienes como organización? El clima dentro de la
                        organización puede llegar a incidir hasta en un 30% de tus resultados.
                    </p>
                    <ViewMore>
                        <p className="description">
                            Te ofrecemos una forma rápida de evaluar el perfil y la cultura de tu equipo,
                            los posibles problemas que pueden existir y proponerte soluciones para lograr los
                            resultados que buscas.
                        </p>
                    </ViewMore>
                </Col>
                <SelectList sections={sections} />
            </Row>
        </Container>
    )
}

export default Teams;