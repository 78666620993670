import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';

const SelectList = ({ sections }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const currentContent = sections[currentIndex].text;
    const currentUrl = sections[currentIndex].moreInfoUrl;

    return (
        <>
        <Col md={4} className="sections-container">
            {sections.map((section, i) => {
                return (
                    <div key={i}
                        className={`list-section ${i === currentIndex? 'selected' : ''}`}
                        onClick={() => setCurrentIndex(i)}
                        >
                        {section.name}
                    </div>
                )
            })}
        </Col>
        <Col md={3}>
            <div className="description">{currentContent}</div>
            <a target="_blank" href={currentUrl} className="more-info" rel="noreferrer">MAS INFORMACIÓN</a>
        </Col>
        </>
    )
}

export default SelectList;