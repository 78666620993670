import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <a target="_blank" href="https://www.rinoscript.com" rel="noreferrer">
                &copy; RinoScript 2021
            </a>
        </div>
    )
}

export default Footer;